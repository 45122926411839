import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";

const cookies = new Cookies();


export var common = {
    api: 'http://oxfordapi.fastsms.uz/',
    // api: 'http://127.0.0.1:8000/',
    // user: cookies.get('user'),
    // username: cookies.get('username'),
    token: cookies.get('token'),
}



export async function getapi(url){
    const api = common.api + url;
    var headers = {
        'Authorization': 'Token '+common.token, 
        'Content-Type': 'application/json'            
    }

    const response_types = await fetch(api, {
        method : 'get',
        headers: new Headers(headers)
    });
    const data = await response_types.json();
    return data;
}



export function messager(json){
    var result = [];
    json.forEach(js => {
        if(!js['Message']){
            switch(js['Lang'].toUpperCase()){
                case 'UZ':
                    switch(js['Rate']){
                        case 0:
                            js['message'] = 'Assalomu aleykum! Bugun '+js['Given name']+' ' + js['Family name'] + ' Oxford International School ' + js['Group'] + ' kursiga qatnashmadi. Iltimos, darslarni o\u2019tkazib yubormaslikga harakat qiling. O\u2019qituvchi telefon raqami:'+js['From'];
                            break;
                        case 2:
                            js['message'] = 'Assalomu aleykum! '+js['Given name']+' ' + js['Family name'] + ' Oxford International School ' + js['Group'] + ' fanidan 2 baho oldi.  Nazoratni kuchaytirib ko\u2019proq shug\u2019ullantirishingizni iltimos qilamiz. O\u2019qituvchi telefon raqami:'+js['From'];
                            break;
                        case 3:
                            js['message'] = 'Assalomu aleykum! '+js['Given name']+' ' + js['Family name'] + ' Oxford International School ' + js['Group'] + ' fanidan 3 baho oldi.  Nazoratni kuchaytirishingizni so\u2019raymiz! O\u2019qituvchi telefon raqami:'+js['From'];
                            break;
                        case 4:
                            js['message'] = 'Assalomu aleykum! '+js['Given name']+' ' + js['Family name'] + ' Oxford International School ' + js['Group'] + ' fanidan 4 baho oldi.  Ko\u2019proq shug\u2019ullantirishingizni so\u2019raymiz! O\u2019qituvchi telefon raqami:'+js['From'];
                            break
                        case 5:
                            js['message'] = 'Assalomu aleykum! '+js['Given name']+' ' + js['Family name'] + ' Oxford International School ' + js['Group'] + ' fanidan 5 baho oldi. E\u2019tiboringiz uchun rahmat! O\u2019qituvchi telefon raqami:'+js['From'];
                            break;
                    }
                    break;
                case 'RU':
                    switch(js['Rate']){
                        case 0:
                            js['message'] = 'Zdravstvuyte! Segodnya '+js['Given name']+' ' + js['Family name'] + ' ne prisutstvoval(a) na kursah ' + js['Group'] + ' v Oxford International School. Staraytes\u2019 ne propuskat\u2019 uroki. Nomer telefona uchitelya:'+js['From'];
                            break;
                        case 2:
                            js['message'] = 'Zdravstvuyte! '+js['Given name']+' ' + js['Family name'] + ' poluchil 2 po ' + js['Group'] + ' v Oxford International School. Prosim vas usilit\u2019 kontrol\u2019 i pobol\u2019she zanimat\u2019sya s nim/ney. Nomer telefona uchitelya:'+js['From'];
                            break;
                        case 3:
                            js['message'] = 'Zdravstvuyte! '+js['Given name']+' ' + js['Family name'] + ' poluchil 3 po ' + js['Group'] + ' v Oxford International School. Prosim vas usilit\u2019 kontrol\u2019. Nomer telefona uchitelya:'+js['From'];
                            break;
                        case 4:
                            js['message'] = 'Zdravstvuyte! '+js['Given name']+' ' + js['Family name'] + ' poluchil 4 po ' + js['Group'] + ' v Oxford International School. Prosim vas udelyat\u2019 bol\u2019she vremeni. Nomer telefona uchitelya:'+js['From'];
                            break
                        case 5:
                            js['message'] = 'Zdravstvuyte! '+js['Given name']+' ' + js['Family name'] + ' poluchil 5 po ' + js['Group'] + ' v Oxford International School. Blagodarim za vnimaniye! Nomer telefona uchitelya:'+js['From'];
                            break;
                    }
                break                    
            }
        }
        else{
            js['message'] = js['Message'];
        }
    result.push(js);
    });
    return result;
}



export function logout(){
    cookies.remove('token', {oath: '/'});
    window.location.href = "../";
}